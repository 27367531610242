import { AuthContext } from 'AuthContext'
import styles from "../../components/Assignment/styles.module.scss"
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { AssignmentContext } from 'components/Assignment/AssignmentContext'
import { toast } from 'react-toastify'
import { saveGuidedLesson, saveLessonInterval, saveTopic } from 'components/Lessons/LessonModule/helpers/lessonHelper'
import { useModel } from '@stem-sims/nexus'
import TopicSection from 'components/Curriculum/Sections/TopicSection'
import assessments, { AssessmentResponse } from 'shared/routes/assessments/assessments'
import AssignmentLesson from './AssignmentLesson'
import { Type } from 'shared/types/moduleTypes'
import AssignmentAssessment from './AssignmentAssessment'
import CustomModal from 'components/Modals/CustomModal'
import { checkIfSectionComplete, loadInitialAssessmentData, loadInitialLessonData, sanitizeSectionHeader, shortenSectionHeader } from './helpers/topicAssignmentHelper'
import AssignmentTopicProgress from './AssignmentTopicProgress'
import { checkIfAssessmentComplete } from './helpers/assessmentHelper'
import { updateAssessment } from 'models/modules'
import { formHasSufficientData, parseFormData } from 'helpers/formDataHelper'
import lessons, { LessonResponse } from 'shared/lessons'
import { TopicStudentAnswers } from 'shared/types/curriculumTypes'
import assignments from 'shared/routes/assignments'
import { AssignmentTopicContext } from './AssignmentTopicContext'
import books from 'shared/routes/curriculum/books'

interface AssignmentTopicProps {
    topicId: string
    initialAssessmentData?: Record<string, string>
    initialTopicData?: TopicStudentAnswers
    submissionId: string
    requireCompletion?: boolean
    activeVideoId?: string
}

function AssignmentTopic({ topicId, initialTopicData, initialAssessmentData, submissionId, requireCompletion, activeVideoId } : AssignmentTopicProps) {
    const authContext = React.useContext(AuthContext)
    const { setActivePart, setOnReturn } = React.useContext(AssignmentContext)
    const [submitting, setSubmitting] = useState(false)

    const resuming = !!initialTopicData
    const [studentAnswers, setStudentAnswers] = useState<TopicStudentAnswers>({})
    const [initialStudentAnswers, setInitialStudentAnswers] = useState<TopicStudentAnswers>({})
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent
    const [showCompleteConfirmModal, setShowCompleteConfirmModal] = useState<boolean>(false)

    const [currentSectionNumber, setCurrentSectionNumber] = useState<number>(0)
    const [sectionHeaders, setSectionHeaders] = useState<string[]>([])
    
    const [assessment, setAssessment] = useState<AssessmentResponse | null>(null)
    const [lesson, setLesson] = React.useState<LessonResponse>(null)
    
    const startTimeRef = React.useRef(new Date().toISOString().slice(0, 19).replace('T', ' '))
    const saveTopicTimerRef = useRef(new Date())
    const prevSavedRef = React.useRef(resuming)

    const topicFormRef = React.useRef(null)

    const [lessonDrawResponses, setLessonDrawResponses] = React.useState({})
    const [assessmentDrawResponses, setAssessmentDrawResponses] = React.useState({})

    const [prevLessonData, setPrevLessonData] = React.useState<Record<string, string>>(null) // needed for going back to lesson section
    const [prevLessonDrawData, setPrevLessonDrawData] = React.useState<Record<string, string>>(null)
    const [prevAssessmentData, setPrevAssessmentData] = React.useState<Record<string, string>>(initialAssessmentData) // needed for going back to assessment section

    const { response: topic } = useModel({
        model: books.getTopic,
        props: { topicId: topicId }
    })
    const currentSection = topic?.sections?.[currentSectionNumber]
    const lessonSection = !!(currentSection?.type === "Lesson"  && currentSection?.lessonId)
    const assessmentSection = !!(currentSection?.type === "Assessment" && currentSection?.assessmentId)
    const lastSection = topic?.sections?.length - 1 === currentSectionNumber

    /**
     * Used to initialize empty student answers, current topic section, 
     * available sections in current topic, completed sections, etc.
     */
    useEffect(() => {
        if (topic?.sections) {
            // initialize the object with essential data such as question id and type to send to backend
            const initialStudentAnswers = {}
            const headers = []
            let count = 1
            topic?.sections?.forEach(section => {
                section?.questions?.forEach(question => {
                    initialStudentAnswers[`s${section.sortOrder}-q${question.sortOrder}`] = {topicQuestionId: question.id, answer: "", questionType: question.type, drawingResponse: null}
                })
                const shortHeader = shortenSectionHeader(section?.header, count) // Use numbers instead for long headers
                headers.push(sanitizeSectionHeader(shortHeader))
                count++
            })
            if (resuming) {
                setStudentAnswers(initialTopicData)
                setInitialStudentAnswers(initialTopicData)
            } else {
                setStudentAnswers(initialStudentAnswers)
                setInitialStudentAnswers(initialStudentAnswers)
            }
            setSectionHeaders(headers)
        }
    }, [topic, resuming, initialTopicData])

    /**
     * Save the topic responses when click on "Save and Return to Assignments"
     */
    useEffect(() => {
        if (setOnReturn) {
            setOnReturn(() => {
                return () => {
                    saveTopic({ topicId, studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: false, prevSavedRef })
                    .catch(err => {
                        toast.error(err?.response?.data?.message ?? "There was an error auto saving the topic assignment. Please try again.")
                        return false 
                    })
                }
            })
        }
    }, [setOnReturn, studentAnswers, submissionId, topicId])

    /**
     * Get Assessment for Explain! section
     */
    useEffect(() => {
        if (!assessmentSection) return
        (async () => {
            const assesssmentResponse = await assessments.findById({ id: currentSection.assessmentId })
            setAssessment(assesssmentResponse)
        })()
    }, [currentSection, assessmentSection])

    /**
     * Get Lesson for Explore section
     */
    useEffect(() => {
        if (!lessonSection) return
        (async () => {
            const lessonResponse = await lessons.findById(currentSection.lessonId)
            setLesson(lessonResponse)
        })()
    }, [currentSection, lessonSection])

    /**
     * Load previous data for either lesson or assessment section
     */
    useEffect(() => {
        if (!lessonSection && !assessmentSection) return

        const loadPrevData = async () => { 
            const submission = (await assignments.getSubmission({submissionId: submissionId})).data
            if (!submission) return

            if (assessmentSection && assessment) {
                const [assessmentData, drawingData] = await loadInitialAssessmentData(assessment?.id, submission.studentId, submission.id)
                setPrevAssessmentData(assessmentData)
                setAssessmentDrawResponses(drawingData)
            } else if (lessonSection && lesson) {
                const [lessonData, drawingData] = await loadInitialLessonData(lesson?.moduleName, lesson?.type, lesson?.number, submission.studentLessonId)
                setPrevLessonData(lessonData)
                setPrevLessonDrawData(drawingData)
            }
        }
        loadPrevData()
    }, [submissionId, lessonSection, assessmentSection, assessment?.id, lesson, assessment])

    const nextSection = async () => {
        if (assessmentSection) {
            await onSubmitAssessment()
        } else if (lessonSection) {
            await onSubmitLesson()
        } else {
            await onSubmit({isCompleted: false})
        }

        if (lastSection) { 
            await onSubmit({isCompleted: true})
            return setActivePart("completed")
        }
        setCurrentSectionNumber(currentSectionNumber + 1)
    }

    const prevSection = async () => {
        if (assessmentSection) {
            await onSubmitAssessment()
        } else if (lessonSection) {
            await onSubmitLesson()
        } else {
            await onSubmit({isCompleted: false})
        }

        setCurrentSectionNumber(currentSectionNumber - 1)
    }

    const continueOrShowModal = () => {
        if (isTeacher) return nextSection()
        if (assessmentSection) {
            const form = document.getElementById("assessment-form") as HTMLFormElement
            const [isComplete, message] = checkIfAssessmentComplete(form, Object.keys(assessmentDrawResponses).length)
            if (requireCompletion && !isComplete) {
                return toast.error(message)
            }
            if(!isComplete) {
                return setShowCompleteConfirmModal(true)
            }
        } else if (lessonSection) {
            const parsedFormData = parseFormData(topicFormRef.current, true)
            const formHasData = formHasSufficientData(parsedFormData, lessonDrawResponses)
            if (requireCompletion && !formHasData) {
                return toast.error("Please fill out the entire lesson before submitting")
            }
            if (!formHasData) {
                return setShowCompleteConfirmModal(true)
            }
        }
        const sectionComplete = checkIfSectionComplete(studentAnswers, currentSectionNumber)
        if (requireCompletion && !sectionComplete) {
            return toast.error("Please fill out the entire section before submitting")
        }
        if (sectionComplete) {
            nextSection()
        } else {
            setShowCompleteConfirmModal(true)
        }
    }

    // Handle multiple choice and text questions
    const handleAnswerChange = (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => {
        setStudentAnswers(prev => ({
            ...prev, 
            [`s${sectionNum}-q${questionsNum}`]: {...prev[`s${sectionNum}-q${questionsNum}`], answer: answer, drawingResponse: drawingResponse}
        }))
    }

    const onSubmit = async ({ isCompleted } : { isCompleted: boolean }) => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return
        setSubmitting(true)
        
        try {
            await saveTopic({ topicId, studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: isCompleted, prevSavedRef })
            setInitialStudentAnswers(studentAnswers)
            if (isCompleted) {
                toast.success("Topic Submitted.")
            }
            setSubmitting(false)
        } catch (err) {
            setSubmitting(false)
            toast.error(err?.response?.data?.message ?? "There was an error submitting the lesson. Please try again.")
        }
    }

    const onSubmitAssessment = async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return

        const form = document.getElementById("assessment-form") as HTMLFormElement
        const putParams = {...parseFormData(form, false), ...assessmentDrawResponses}
        await updateAssessment({
            moduleName: assessment?.module,
            assessmentType: assessment?.type as Type,
            assessmentNumber: assessment?.number,
            submissionId: submissionId,
            putParams: putParams
        })
    }

    const onSubmitLesson = async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return

        saveGuidedLesson({lesson: lesson, completed: true, isTeacher: false, lessonFormRef: topicFormRef,
            submissionId: submissionId, drawingResponses: lessonDrawResponses, allowEmpty: true })
    }

    const debouncedSaveTopic = useCallback(async () => {
        if(!authContext.isLoggedIn || isTeacher || !authContext.trackingEnabled) return

        const now = new Date()
        if (now.getTime() - saveTopicTimerRef.current.getTime() < saveLessonInterval) {
            return
        }
        saveTopicTimerRef.current = now
        
        saveTopic({ topicId, studentAnswers, submissionId, startTime: startTimeRef.current, isCompleted: false, prevSavedRef })
            .catch(err => {
                toast.error(err?.response?.data?.message ?? "There was an error auto saving the topic assignment. Please try again.")
                return false 
            })
    }, [authContext.isLoggedIn, authContext.trackingEnabled, isTeacher, prevSavedRef, submissionId, studentAnswers, topicId])

    return (<>
            <CustomModal
                showModal={showCompleteConfirmModal}
                header={lastSection ? `Finish Topic Assignment` : `Submit ${currentSection?.header}`}
                body={`${lastSection ? `Are you sure you want to complete this topic assignment?` : 
                    `Are you sure you want to submit this section?`} Have you completed all Questions associated with this section?`}
                onConfirm={() => {
                    nextSection()
                    setShowCompleteConfirmModal(false)
                }}
                onDecline={() => setShowCompleteConfirmModal(false)}
                confirmBtnText="Yes"
                declineBtnText="No"
            />

            <Row className={`${styles.assignmentContents}`}>
                <Form className={`${styles.lessonTabs} h-100`}>
                    {!lessonSection && !assessmentSection && 
                        <TopicSection
                            currentSection={currentSection}
                            studentAnswers={studentAnswers}
                            initialStudentAnswers={initialStudentAnswers}
                            setStudentAnswers={setStudentAnswers}
                            handleAnswerChange={handleAnswerChange}
                            debouncedSaveTopic={debouncedSaveTopic}
                        />}
                    {assessmentSection && assessment &&
                        <AssignmentTopicContext.Provider
                            value={{assessmentDrawResponses: assessmentDrawResponses, setAssessmentDrawResponses: setAssessmentDrawResponses}}>
                            <AssignmentAssessment
                                isPartOfTopic={true}
                                initialAssessmentData={prevAssessmentData}
                                assessmentId={assessment?.id}
                                submissionId={submissionId}
                                moduleName={assessment?.module}
                                requireCompletion={requireCompletion}
                                assessmentNumber={assessment?.number}
                                type={assessment?.type as Type}
                                submitted={null}
                            />
                        </AssignmentTopicContext.Provider>}
                    {lessonSection && lesson &&
                        <AssignmentTopicContext.Provider
                            value={{lessonDrawResponses: lessonDrawResponses, setLessonDrawResponses: setLessonDrawResponses}}>
                            <AssignmentLesson
                                topicLessonId={lesson.id}
                                submissionId={submissionId}
                                moduleName={lesson.moduleName}
                                type={lesson.type}
                                videoId={activeVideoId}
                                hasAssessment={false}
                                initialLessonData={prevLessonData}
                                initialDrawingData={prevLessonDrawData}
                                initialPureInquiryData={null}
                                lessonVariety={"Guided"}
                                preSubmit={null}
                                isPartOfTopic={true}
                                topicFormRef={topicFormRef}
                            />
                        </AssignmentTopicContext.Provider>}
                </Form>
            </Row>
            <Row className={`${styles.assignmentProgress} my-3`}>
                <Col sm={12} xl={8} className={`${styles.fitContent} m-auto`}>
                    <AssignmentTopicProgress 
                        currentSectionNumber={currentSectionNumber}
                        sectionHeaders={sectionHeaders} />
                </Col>
                <Col sm={12} xl={4} className={`text-end ${styles.fitContent} d-flex justify-content-center`}>
                    <Button
                        variant="outline-theme"
                        className="btn-lg me-3"
                        disabled={currentSectionNumber === 0}
                        onClick={prevSection}>
                            Previous
                    </Button>
                    <Button
                        disabled={submitting}
                        variant="outline-theme"
                        className="btn-lg me-3"
                        onClick={continueOrShowModal}>
                            {isTeacher ? `Continue` : lastSection ? `Finish Assignment` : `Save and Continue`}
                    </Button>
                </Col>
            </Row>
        </>
    )
}

export default AssignmentTopic
