import { useContext } from 'react'
import assignmentStyles from "../../../components/Assignment/assessment.module.scss"
import styles from "../../../components/Lessons/LessonModule/styles.module.scss"
import { AuthContext } from "AuthContext"
import { CurriculumSection, TopicStudentAnswers } from 'shared/types/curriculumTypes'
import { Form } from 'react-bootstrap'
import DrawingQuestion from 'components/Assignment/components/DrawingQuestion'
import DrawingImageButton from 'pages/Dashboard/Teacher/Grades/Submission/components/DrawingImageButton'

interface SectionQuestionsProps {
    currentSection: CurriculumSection
    studentAnswers: TopicStudentAnswers
    handleAnswerChange: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
    debouncedSaveTopic: () => void
}

function SectionQuestions({ currentSection, studentAnswers, handleAnswerChange, debouncedSaveTopic } : SectionQuestionsProps) {
    const authContext = useContext(AuthContext)
    const isTeacher = authContext.isLoggedIn && !authContext.isStudent
    const sectionNumber = currentSection.sortOrder

    return (
        <ol className='list-group list-group-flush text-start'>
            {currentSection.questions?.map((question, index) => (
                <li key={index} className='list-group-item'>
                    <h6 className='fw-bold'>{index + 1}. <span dangerouslySetInnerHTML={{ __html: question.question }}></span></h6>
                    {question?.answers?.length > 1 ? question?.answers?.map((answer, answerIndex) => (
                        <div
                            key={answerIndex}
                            className={`${assignmentStyles.answerOption} text-start`}
                        >
                            <input
                                type="radio"
                                id={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`}
                                name={`s${sectionNumber}-q${question.sortOrder}`}
                                value={answer.letter}
                                checked={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer === answer.letter}
                                onChange={() => {
                                    handleAnswerChange(sectionNumber, question.sortOrder, answer.letter, null)
                                    debouncedSaveTopic()
                                }}
                            />
                            <label htmlFor={`s${sectionNumber}-q${question.sortOrder}a${answerIndex}`} className="ms-1">
                                <span className='me-2'>{answer.letter}.</span>
                                <span dangerouslySetInnerHTML={{ __html: answer.answer }} />
                            </label>
                        </div>
                    )) : question.type === "Drawing" ? 
                        <DrawingQuestion 
                            questionType="topic"
                            topicQuestion={question}
                            initialTopicDrawingData={studentAnswers?.[`s${currentSection.sortOrder}-q${question.sortOrder}`]?.drawingResponse}
                            sectionNumber={currentSection.sortOrder}
                            handleAnswerChange={handleAnswerChange}
                            autoSave={debouncedSaveTopic}
                        /> :
                        <Form.Control 
                            as="textarea"
                            className={`${styles.lessonInput} input-glow ms-auto`}
                            name={`s${sectionNumber}-q${question.sortOrder}`}
                            value={studentAnswers[`s${sectionNumber}-q${question.sortOrder}`]?.answer}
                            onChange={(e) => handleAnswerChange(sectionNumber, question.sortOrder, e.target.value, null)}
                            onBlur={debouncedSaveTopic} />}
                    {isTeacher && 
                        <strong className="text-success d-block my-3">
                            {question.type !== "Drawing" &&
                                <>
                                    <span>Correct Answer: </span>
                                    <span dangerouslySetInnerHTML={{ __html: question.correctAnswer}} />
                                </>}
                           {question.type === "Drawing" &&
                                <div className="d-flex justify-content-center">
                                    <DrawingImageButton 
                                        drawingUrl={question.exampleImageUrl}
                                        title="Example Drawing" 
                                        optionalAnswer={question.correctAnswer} />
                                </div>}
                        </strong>}
                </li>
            ))}
        </ol>
    )
}

export default SectionQuestions
